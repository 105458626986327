import type { ReactNode } from "react";
import Head from "next/head";

import {
  Box,
  Container,
  Image as ChakraImage,
  useColorModeValue,
} from "@chakra-ui/react";

import NavBar from "components/layout/top-nav-bar";
import Footer from "components/layout/footer";

type ContainerProps = {
  children: ReactNode;
  title?: string;
};

export default function IndexContainer({
  children,
  title = `NFT One — Explore finest digital collection soon`,
}: ContainerProps) {
  return (
    <Box>
      <Head>
        <title>{title}</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta name="title" content={title} />
        <meta name="description" content="NFT Marketplace for art collectors" />

        {/* OG */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://nftone.io/" />
        <meta property="og:title" content={title} />
        <meta
          property="og:description"
          content="NFT Marketplace for art collectors"
        />
        <meta
          property="og:image"
          content="https://nftone.io/images/default-image/meta-tag-image-default.png"
        />

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://nftone.io/" />
        <meta property="twitter:title" content={title} />
        <meta
          property="twitter:description"
          content="NFT Marketplace for art collectors"
        />
        <meta
          property="twitter:image"
          content="https://nftone.io/images/default-image/meta-tag-image-default.png"
        />
      </Head>
      <Box
        position={"absolute"}
        display={{ base: "none", md: "block" }}
        top={0}
        left={0}
        zIndex={{ md: "base" }}
      >
        <Box width={{ base: 40, md: 64 }}>
          <ChakraImage
            src="/images/curve-bg.png"
            alt={`Curve BG`}
            width={686}
          />
        </Box>
      </Box>
      <Container
        width={"full"}
        position={"relative"}
        maxW={"100vw"}
        minH={"100vh"}
        overflowX={"hidden"}
        zIndex={10}
        px={0}
      >
        {children}
        <Footer />
      </Container>
    </Box>
  );
}
