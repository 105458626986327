import { useEffect, useRef, useState } from "react";

import NextLink from "next/link";

import {
  AspectRatio,
  Avatar,
  Box,
  Button,
  Image as ChakraImage,
  Container,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  LinkBox,
  LinkOverlay,
  Skeleton,
  SkeletonCircle,
  useColorModeValue,
} from "@chakra-ui/react";

import { FaArrowRight } from "react-icons/fa";

import SwiperCore, { Grid as GridSwiper, Virtual } from "swiper";
import "swiper/css/bundle";
import { Swiper, SwiperSlide } from "swiper/react";

import { GraphQLQuery } from "@aws-amplify/api";
import { API, graphqlOperation } from "aws-amplify";
import { Collection, Item, ItemByCollectionQuery } from "src/API";
import { itemByCollection } from "src/graphql/queries";

interface TopGalleriesPropsObject {
  galleries: Collection[];
  loaded: boolean;
}

export default function TopGallery({
  loaded,
  galleries,
}: TopGalleriesPropsObject) {
  const buttonBorderColor = useColorModeValue("gray.500", "alpha.purple");
  const buttonBorderColorHover = useColorModeValue("gray.900", "white");
  const swiperRef = useRef<SwiperCore>(null) as any;
  const [isFirst, setIsFirst] = useState<boolean>(true);
  const [isLast, setIsLast] = useState<boolean>(true);

  const handlePrev = () => {
    if (swiperRef.current && !isFirst) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNext = () => {
    if (swiperRef.current && !isLast) {
      swiperRef.current.swiper.slideNext();
    }
  };

  return (
    <Container
      position={"relative"}
      maxW={{ base: "container.xl", "2xl": "container.2xl" }}
      px={4}
      pt={14}
      pb={7}
      zIndex={2}
    >
      <Flex alignItems={"center"} gap={{ base: 4, lg: 8 }}>
        <Heading
          as={"h2"}
          fontWeight={"medium"}
          fontSize={"5xl"}
          width={{ base: "full", lg: "fit-content" }}
        >
          Galleries
        </Heading>
        <Flex
          display={"inline-flex"}
          ml={"auto"}
          gap={{ base: 2, lg: 4 }}
          role="group"
        >
          <Button
            onClick={handlePrev}
            isDisabled={isFirst}
            h={12}
            w={12}
            fontSize={"3xl"}
            fontWeight={"bold"}
            rounded={"full"}
            bg={"transparent"}
            borderWidth={1}
            borderColor={buttonBorderColor}
            _hover={{
              color: "main.blue",
              bg: "transparent",
              borderColor: buttonBorderColorHover,
            }}
          >
            &#60;
          </Button>
          <Button
            onClick={handleNext}
            isDisabled={isLast}
            h={12}
            w={12}
            fontSize={"3xl"}
            fontWeight={"bold"}
            rounded={"full"}
            bg={"transparent"}
            borderWidth={1}
            borderColor={buttonBorderColor}
            _hover={{
              color: "main.blue",
              bg: "transparent",
              borderColor: buttonBorderColorHover,
            }}
          >
            &#62;
          </Button>
        </Flex>
      </Flex>

      <Box>
        <Swiper
          style={{ paddingTop: "3rem", paddingBottom: "2rem" }}
          slidesPerView={1}
          spaceBetween={10}
          breakpoints={{
            640: {
              slidesPerView: 2,
              spaceBetween: 10,
              grid: { rows: 2, fill: "row" },
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 20,
              grid: { rows: 2, fill: "row" },
            },
          }}
          modules={[GridSwiper, Virtual]}
          onInit={(core: SwiperCore) => {
            swiperRef.current = core.el;
          }}
          onUpdate={(swiper) => {
            setIsFirst(false);
            setIsLast(false);
            if (swiper.isBeginning == true) {
              setIsFirst(true);
            }
            if (swiper.isEnd == true) {
              setIsLast(true);
            }
          }}
          onSlideChange={(swiper) => {
            setIsFirst(false);
            setIsLast(false);
            if (swiper.isBeginning == true) {
              setIsFirst(true);
            }
            if (swiper.isEnd == true) {
              setIsLast(true);
            }
          }}
          virtual={false}
        >
          {!loaded
            ? [...Array(6)].map((i, x) => (
                <SwiperSlide key={x}>
                  <SlideGallerySkeleton />
                </SwiperSlide>
              ))
            : galleries.length > 0 &&
              galleries.map((item, index) => (
                <SwiperSlide key={index} virtualIndex={index}>
                  <SlideGallery items={item} />
                </SwiperSlide>
              ))}
        </Swiper>
      </Box>
    </Container>
  );
}

export function ComingSoonGallery({
  loaded,
  galleries,
}: TopGalleriesPropsObject) {
  const buttonBorderColor = useColorModeValue("gray.500", "alpha.purple");
  const buttonBorderColorHover = useColorModeValue("gray.900", "white");
  const swiperRef = useRef<SwiperCore>(null) as any;
  const [isFirst, setIsFirst] = useState<boolean>(true);
  const [isLast, setIsLast] = useState<boolean>(true);

  const handlePrev = () => {
    if (swiperRef.current && !isFirst) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNext = () => {
    if (swiperRef.current && !isLast) {
      swiperRef.current.swiper.slideNext();
    }
  };

  return (
    <Container
      position={"relative"}
      maxW={{ base: "container.xl", "2xl": "container.2xl" }}
      px={4}
      pt={14}
      pb={7}
      zIndex={2}
    >
      <Flex
        flexWrap={"wrap"}
        flexDirection={"row"}
        alignItems={"center"}
        gap={{ base: 4, lg: 8 }}
      >
        <Heading
          as={"h2"}
          fontWeight={"medium"}
          fontSize={"5xl"}
          width={{ base: "full", lg: "fit-content" }}
        >
          Coming Soon
        </Heading>
        <Flex
          display={"inline-flex"}
          ml={"auto"}
          gap={{ base: 2, lg: 4 }}
          role="group"
        >
          <Button
            onClick={handlePrev}
            isDisabled={isFirst}
            h={12}
            w={12}
            fontSize={"3xl"}
            fontWeight={"bold"}
            rounded={"full"}
            bg={"transparent"}
            borderWidth={1}
            borderColor={buttonBorderColor}
            _hover={{
              color: "main.blue",
              bg: "transparent",
              borderColor: buttonBorderColorHover,
            }}
          >
            &#60;
          </Button>
          <Button
            onClick={handleNext}
            isDisabled={isLast}
            h={12}
            w={12}
            fontSize={"3xl"}
            fontWeight={"bold"}
            rounded={"full"}
            bg={"transparent"}
            borderWidth={1}
            borderColor={buttonBorderColor}
            _hover={{
              color: "main.blue",
              bg: "transparent",
              borderColor: buttonBorderColorHover,
            }}
          >
            &#62;
          </Button>
        </Flex>
      </Flex>

      <Box cursor={"grab"} _active={{ cursor: "grabbing" }}>
        <Swiper
          style={{ paddingTop: "3rem", paddingBottom: "2rem" }}
          slidesPerView={1}
          spaceBetween={10}
          breakpoints={{
            640: {
              slidesPerView: 2,
              spaceBetween: 10,
              grid: { rows: 2, fill: "row" },
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 20,
              grid: { rows: 2, fill: "row" },
            },
          }}
          modules={[GridSwiper, Virtual]}
          onInit={(core: SwiperCore) => {
            swiperRef.current = core.el;
          }}
          onUpdate={(swiper) => {
            setIsFirst(false);
            setIsLast(false);
            if (swiper.isBeginning == true) {
              setIsFirst(true);
            }
            if (swiper.isEnd == true) {
              setIsLast(true);
            }
          }}
          onSlideChange={(swiper) => {
            setIsFirst(false);
            setIsLast(false);
            if (swiper.isBeginning == true) {
              setIsFirst(true);
            }
            if (swiper.isEnd == true) {
              setIsLast(true);
            }
          }}
          virtual={false}
        >
          {!loaded
            ? [...Array(6)].map((i, x) => (
                <SwiperSlide key={x}>
                  <SlideGallerySkeleton />
                </SwiperSlide>
              ))
            : galleries.length > 0 &&
              galleries.map((item, index) => (
                <SwiperSlide key={index} virtualIndex={index}>
                  <SlideGallery items={item} />
                </SwiperSlide>
              ))}
        </Swiper>
      </Box>
    </Container>
  );
}

const SlideGallery = (props: { items: Collection }) => {
  const { id, slug, name, description, thumbnailUrl, creator, status } =
    props.items;

  const bgSlideGallery = useColorModeValue("white", "gradient.purpleDark");
  const bgSlideGalleryHover = useColorModeValue(
    "gradient.purpleToBlueLight",
    "gradient.purpleToBlueDark"
  );

  const [items, setItems] = useState<{ loaded: boolean; items: Item[] }>({
    loaded: false,
    items: [],
  });

  useEffect(() => {
    var mounted: boolean = true;

    const getListItems = async () => {
      try {
        const items = await API.graphql<GraphQLQuery<ItemByCollectionQuery>>(
          graphqlOperation(itemByCollection, {
            limit: 4,
            collectionId: id,
          })
        );

        if (items.data?.itemByCollection?.items) {
          if (mounted) {
            setItems({
              loaded: true,
              items: items.data.itemByCollection.items as Item[],
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    getListItems();

    return () => {
      mounted = false;
    };
  }, [id]);

  return (
    <LinkBox
      as="div"
      display={"block"}
      w={"full"}
      transform={"scale(0.95)"}
      transitionDuration={".15s"}
      transitionProperty={"transform box-shadow"}
      shadow={"lg"}
      _hover={{
        transform: "scale(1) translateY(-1.5rem)",
        shadow: "xl",
      }}
    >
      <Box rounded={"xl"} p={"1px"} bg={"gradient.purpleRainbow"}>
        <Box
          width={"full"}
          height={"full"}
          overflow={"hidden"}
          rounded={"xl"}
          p={5}
          gap={6}
          bg={bgSlideGallery}
          _hover={{
            bg: bgSlideGalleryHover,
          }}
        >
          <LinkOverlay
            as={NextLink}
            href={`/collection/${slug}`}
            cursor={"pointer"}
            aria-label={`Read more collection ${name}`}
          ></LinkOverlay>
          <Grid
            templateColumns={"2fr 1fr"}
            templateRows={"repeat(3, minmax(0, 1fr))"}
            gap={3}
          >
            {!items.loaded ? (
              <>
                <GridItem
                  rounded={"xl"}
                  overflow={"hidden"}
                  rowSpan={3}
                  colSpan={2}
                >
                  <Skeleton width={"full"} height={"full"} />
                </GridItem>
                <GridItem rounded={"xl"} overflow={"hidden"} h={24}>
                  <Skeleton width={"full"} height={"full"} />
                </GridItem>
                <GridItem rounded={"xl"} overflow={"hidden"} h={24}>
                  <Skeleton width={"full"} height={"full"} />
                </GridItem>
                <GridItem rounded={"xl"} overflow={"hidden"} h={24}>
                  <Skeleton width={"full"} height={"full"} />
                </GridItem>
              </>
            ) : (
              items.items.map((i, x, arr) =>
                x == 0 ? (
                  <GridItem
                    key={x}
                    colSpan={items.items.length === 1 ? 2 : 1}
                    rowSpan={3}
                    height={"350px"}
                    bg={"gray.400"}
                    rounded={"xl"}
                    position={"relative"}
                    overflow={"hidden"}
                    role={"group"}
                  >
                    <LinkBox w={"full"} h={"full"}>
                      <ImageGallery image={i?.imageUrl} name={i.name} />
                      <LinkOverlay
                        as={NextLink}
                        href={`/item/${i?.id}`}
                        aria-label={`Read more detail about ${i.name}`}
                      />
                    </LinkBox>
                  </GridItem>
                ) : (
                  <GridItem
                    key={x}
                    h={24}
                    bg={"gray.400"}
                    rounded={"xl"}
                    position={"relative"}
                    overflow={"hidden"}
                    role={"group"}
                  >
                    {x == arr.length - 1 ? (
                      <LinkBox position={"relative"} w={"full"} h={"full"}>
                        <Flex
                          flexDirection={"column"}
                          gap={0}
                          position={"absolute"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          zIndex={"1"}
                          w={"full"}
                          h={"full"}
                          bg={"blackAlpha.600"}
                          _groupHover={{ transform: "scale(1.15)" }}
                          transition={"all 0.25s ease-in-out"}
                          fontSize={"sm"}
                          color={"gray.50"}
                        >
                          View All
                          <LinkOverlay
                            as={NextLink}
                            href={`collection/${i.collection?.slug}`}
                            aria-label={`Read more detail about ${i.name}`}
                          >
                            <Icon
                              display={"block"}
                              fontSize={"xl"}
                              as={FaArrowRight}
                            />
                          </LinkOverlay>
                        </Flex>
                        <ImageGallery image={i?.imageUrl} name={i.name} />
                      </LinkBox>
                    ) : (
                      <LinkBox w={"full"} h={"full"}>
                        <ImageGallery image={i?.imageUrl} name={i.name} />
                        <LinkOverlay
                          as={NextLink}
                          href={`/item/${i?.id}`}
                          aria-label={`Read more detail about ${i.name}`}
                        />
                      </LinkBox>
                    )}
                  </GridItem>
                )
              )
            )}
          </Grid>
          <Flex alignItems={"center"} mt={6}>
            <Avatar
              display={{ base: "none", md: "inline-flex" }}
              bg={"transparent"}
              size={"sm"}
              src={
                thumbnailUrl
                  ? thumbnailUrl
                  : "/images/default-image/nftone-square.webp"
              }
            />
            <Flex mr={"auto"} color={"white"} alignItems={"center"}>
              <Heading
                as={"div"}
                ml={{ base: 0, md: 2.5 }}
                fontSize={"sm"}
                fontWeight={"semibold"}
                color={useColorModeValue("gray.900", "gray.50")}
              >
                {name}
              </Heading>
            </Flex>
          </Flex>
        </Box>
      </Box>
    </LinkBox>
  );
};

const SlideGallerySkeleton = () => {
  const bgSlideGallery = useColorModeValue("gray.50", "gradient.purpleDark");

  return (
    <Box
      rounded={"xl"}
      bg={"gradient.purpleRainbow"}
      transform={"scale(0.95)"}
      p={"1px"}
    >
      <Grid
        templateColumns={"repeat(3, minmax(0, 1fr))"}
        templateRows={"repeat(3, minmax(0, 1fr))"}
        columnGap={3}
        rowGap={3}
        bg={bgSlideGallery}
        rounded={"xl"}
        p={5}
      >
        <GridItem rounded={"xl"} overflow={"hidden"} rowSpan={3} colSpan={2}>
          <Skeleton width={"full"} height={"full"} />
        </GridItem>
        <GridItem rounded={"xl"} overflow={"hidden"} h={24}>
          <Skeleton width={"full"} height={"full"} />
        </GridItem>
        <GridItem rounded={"xl"} overflow={"hidden"} h={24}>
          <Skeleton width={"full"} height={"full"} />
        </GridItem>
        <GridItem rounded={"xl"} overflow={"hidden"} h={24}>
          <Skeleton width={"full"} height={"full"} />
        </GridItem>
        <GridItem rounded={"xl"} colSpan={3}>
          <Flex alignItems={"center"} gap={4} mx={"1px"} mb={"1px"} mt={3}>
            <SkeletonCircle size={"8"} />
            <Skeleton h={3} w={32} />
          </Flex>
        </GridItem>
      </Grid>
    </Box>
  );
};

const ImageGallery = ({ image, name }: { image?: string; name?: string }) => {
  const bgImage = useColorModeValue("gray.200", "main.newDarkBlue");

  return (
    <AspectRatio
      ratio={1}
      bg={bgImage}
      _groupHover={{ transform: "scale(1.05)" }}
      transition={"all 0.25s ease-in-out"}
      position={"relative"}
      m={"auto"}
      h={"full"}
    >
      <ChakraImage
        src={image ? image : "/images/default-image/default-banner-2.webp"}
        alt={name ? name : "Default Image"}
        objectFit={"cover"}
        objectPosition={"center"}
        w={"full"}
        h={"full"}
        fallback={<Skeleton w={"full"} h={"full"} />}
      />
    </AspectRatio>
  );
};
