import {
  AspectRatio,
  Box,
  Button,
  Image as ChakraImage,
  Container,
  Flex,
  Heading,
  Link,
  Skeleton,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { useState } from 'react';
import { Item } from 'src/API';

const stackPos = (index: number) => {
  const pos = [
    {
      base: '100px',
      md: '200px',
    },
    {
      base: '0px',
      md: '0px',
    },
    {
      base: '-100px',
      md: '-200px',
    },
  ];

  return pos[index];
};

export default function HeroSection2({
  loaded,
  items,
}: {
  loaded: boolean;
  items?: Item[];
}) {
  const [activeIndex, setActiveIndex] = useState<number>(1);

  return (
    <Container
      position={'relative'}
      maxW={{ base: 'container.xl', '2xl': 'container.2xl' }}
      px={{ base: 4, lg: 0 }}
      my={8}
      zIndex={2}
    >
      <Flex
        alignItems={'center'}
        justifyContent={'space-between'}
        flexDirection={{ base: 'column-reverse', md: 'row' }}
        py={{ base: 4, md: 20 }}
        gap={16}
      >
        <Box
          width={{ base: 'full', md: '50%' }}
          mx={{ lg: 8 }}
          textAlign={{ base: 'center', md: 'left' }}
        >
          <Heading
            as={'h1'}
            fontWeight={'semibold'}
            fontSize={{ base: '2xl', md: '3xl', lg: '2.5rem' }}
            lineHeight={'1.25'}
          >
            Physical{' '}
            <Text
              as="span"
              bgGradient={'linear(to-r, main.magenta, main.purple ,main.blue)'}
              bgClip={'text'}
            >
              NFT Gallery
            </Text>
            <br />
            unlike no others
          </Heading>
          <Text fontSize={{ base: 'md', lg: 'xl' }} fontWeight={'light'} mt={4}>
            NFT One expands outside web3 by building physical exhibition to
            display your NFTs
          </Text>

          <Flex mt={12} justifyContent={{ base: 'center', md: 'flex-start' }}>
            <Button
              as={Link}
              href={'https://www.instagram.com/nftone.io/'}
              target={'_blank'}
              variant={'gradient'}
              size={'lg'}
            >
              Visit Gallery
            </Button>
          </Flex>
        </Box>

        <Flex
          alignItems={'center'}
          position={'relative'}
          width={{ base: 'full', md: '50%' }}
          minHeight={{ base: 56, md: 64, lg: 96 }}
        >
          {!loaded
            ? [1, 2, 3].map((i, x) => (
                <Box
                  key={x}
                  onMouseEnter={() => setActiveIndex(x)}
                  onMouseLeave={() => setActiveIndex(1)}
                >
                  <HeroImageSkeleon
                    image={'' + i}
                    index={x}
                    active={x == activeIndex ? true : false}
                  />
                </Box>
              ))
            : items !== undefined &&
              items.map((item, index) => (
                <Box
                  key={index}
                  onMouseEnter={() => setActiveIndex(index)}
                  onMouseLeave={() => setActiveIndex(1)}
                >
                  <HeroImage
                    image={item.imageUrl}
                    index={index}
                    active={index == activeIndex ? true : false}
                  />
                </Box>
              ))}
        </Flex>
      </Flex>
    </Container>
  );
}

const HeroImage = ({
  image,
  index,
  active,
}: {
  image: string;
  index: number;
  active: boolean;
}) => {
  return (
    <Box
      m={'auto'}
      height={{ base: 56, md: 56, lg: 72, xl: 96 }}
      width={{ base: 56, md: 56, lg: 72, xl: 96 }}
      position={'absolute'}
      top={{ base: 0, md: 10, lg: 10, xl: 0 }}
      right={0}
      left={{
        base: stackPos(index).base,
        md: stackPos(index).md,
      }}
      zIndex={active ? 2 : 1}
      transform={active ? '' : 'scale(0.85)'}
      transition={'all 0.25s ease-in-out'}
      shadow={'lg'}
      _hover={{ shadow: 'xl' }}
    >
      <Box
        width={'full'}
        height={'full'}
        position={'relative'}
        overflow={'hidden'}
        rounded={'lg'}
      >
        <AspectRatio ratio={1}>
          <ChakraImage
            src={image}
            objectFit={'cover'}
            alt={image}
            w={'full'}
            h={'full'}
            fallback={<Skeleton w={'full'} h={'full'} />}
          />
        </AspectRatio>
      </Box>
    </Box>
  );
};

const HeroImageSkeleon = ({
  image,
  index,
  active,
}: {
  image: string;
  index: number;
  active: boolean;
}) => {
  const heroImageBg = useColorModeValue('gray.200', 'main.darkblue');

  return (
    <Box
      m={'auto'}
      height={{ base: 56, md: 64, lg: 96 }}
      width={{ base: 56, md: 64, lg: 96 }}
      position={'absolute'}
      top={0}
      right={0}
      left={{
        base: stackPos(index).base,
        md: stackPos(index).md,
      }}
      zIndex={active ? 2 : 1}
      transform={active ? '' : 'scale(0.85)'}
      transition={'all 0.25s ease-in-out'}
      bg={heroImageBg}
    >
      <Box
        width={'full'}
        height={'full'}
        position={'relative'}
        overflow={'hidden'}
      >
        <Skeleton width={'full'} height={'full'} rounded={'xl'} />
      </Box>
    </Box>
  );
};
