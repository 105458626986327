import NextLink from 'next/link';
import { useRef, useState } from 'react';

import {
  AspectRatio,
  Box,
  Button,
  Image as ChakraImage,
  Container,
  Flex,
  Heading,
  Skeleton,
  Stack,
  Text,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react';
import { Collection, CollectionStatus } from 'src/API';

import { useParameterStore } from 'utils/context';

import SwiperCore, { Navigation, Pagination, Virtual } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

export default function Hero(props: {
  collections: Collection[];
  loaded?: boolean;
}) {
  const { loaded, collections } = props;

  const { userProfile } = useParameterStore();
  const backgroundOverlay = useColorModeValue(
    'blackAlpha.600',
    'main.spaceCadet'
  );
  const swiperPageOverlay = useColorModeValue(
    'blackAlpha.500',
    'blackAlpha.700'
  );
  const swiperPageColor = useColorModeValue('gray.300', 'main.spaceCadet');

  const swiperRef = useRef<SwiperCore>(null) as any;
  const [swiperIndex, setSwiperIndex] = useState<number>(0);

  const slideTo = (index: number) => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideTo(index);
    }
  };

  return (
    <>
      <Container
        px={{ base: 0, lg: 4 }}
        maxW={{ base: 'container.xl', '2xl': 'container.2xl' }}
      >
        <Flex
          position={'relative'}
          mt={{ base: 0, lg: 14 }}
          overflow={'hidden'}
          rounded={{ base: 'none', lg: '2xl' }}
          shadow={'dark-lg'}
          height={{ base: '550px', lg: '450px' }}
          alignItems={'center'}
          bg={
            collections.length > 0 && loaded ? backgroundOverlay : 'transparent'
          }
        >
          {collections.length > 0 && loaded ? (
            <>
              <Box
                position={'absolute'}
                w={'full'}
                h={'full'}
                left={0}
                top={0}
                bgImage={
                  collections[swiperIndex].bannerUrl
                    ? `url('${collections[swiperIndex].bannerUrl}')`
                    : `url('/images/default-image/default-banner-1.webp')`
                }
                bgSize={'cover'}
                bgPosition={'center'}
                filter={'blur(20px)'}
                transitionProperty={'background-image'}
                transitionDuration={'.3s'}
                transitionTimingFunction={'cubic-bezier(.4, 0, .2, 1)'}
                willChange={'transform'}
              />
              <Box
                position={'absolute'}
                w={'full'}
                h={'full'}
                left={0}
                top={0}
                bg={swiperPageOverlay}
              />
              <Swiper
                onInit={(core: SwiperCore) => {
                  swiperRef.current = core.el;
                }}
                onUpdate={(swiper) => {
                  setSwiperIndex(swiper.realIndex);
                }}
                onSlideChange={(swiper) => {
                  setSwiperIndex(swiper.realIndex);
                }}
                modules={[Virtual, Navigation, Pagination]}
                virtual
                grabCursor
              >
                {collections.length > 0
                  ? collections.map((collection, index) => (
                      <SwiperSlide key={index}>
                        <Flex
                          alignItems={'center'}
                          position={'relative'}
                          justifyContent={'space-between'}
                          flexDirection={{
                            base: 'column-reverse',
                            md: 'row',
                          }}
                          mx={{ lg: 6 }}
                          p={6}
                          px={12}
                          gap={{ base: 6, md: 2, lg: 12 }}
                        >
                          <Stack
                            gap={{ base: 4, md: 12, xl: 12 }}
                            width={{ base: 'full', md: '60%', lg: '50%' }}
                            textAlign={{ base: 'center', md: 'left' }}
                            zIndex={4}
                            color={'gray.50'}
                          >
                            <Box>
                              <Heading
                                as={'h1'}
                                fontWeight={'semibold'}
                                fontSize={{
                                  base: '2xl',
                                  md: '3xl',
                                  lg: '2.5rem',
                                }}
                                lineHeight={'1.25'}
                              >
                                {collection.name}
                              </Heading>
                              <Text fontWeight={'light'} mt={2} noOfLines={4}>
                                {collection.description}
                              </Text>
                            </Box>

                            <Flex
                              gap={6}
                              justifyContent={{
                                base: 'center',
                                md: 'flex-start',
                              }}
                              alignItems={'center'}
                              flexDirection={{
                                base: 'column',
                                sm: 'row',
                                lg: 'row',
                              }}
                            >
                              <Button
                                as={NextLink}
                                href={
                                  collection.status ===
                                    CollectionStatus.AVAILABLE ||
                                  collection.status ===
                                    CollectionStatus.COMING_SOON
                                    ? `/launchpad/collection/${collection.slug}`
                                    : `/collection/${collection.slug}`
                                }
                                size={{ base: 'md', lg: 'lg' }}
                                variant={'gradient'}
                                fontWeight={'semibold'}
                                title={`Read more about ${collection.name} collection`}
                                aria-label={`Read more about ${collection.name} collection`}
                              >
                                Check It Out!
                              </Button>
                            </Flex>
                          </Stack>
                          <Box>
                            <AspectRatio
                              ratio={1}
                              width={{ base: 64, md: 64, lg: 96 }}
                              overflow={'hidden'}
                              rounded={'lg'}
                            >
                              <ChakraImage
                                width={'full'}
                                height={'full'}
                                src={String(collection.thumbnailUrl)}
                              />
                            </AspectRatio>
                          </Box>
                        </Flex>
                      </SwiperSlide>
                    ))
                  : null}
              </Swiper>
            </>
          ) : (
            <Skeleton width={'full'} height={'full'} />
          )}
        </Flex>
      </Container>
      <Container maxW={{ base: 'container.xl', '2xl': 'container.2xl' }}>
        <VStack my={6}>
          <Flex
            gap={4}
            w={{ base: '80%', lg: '50%' }}
            justifyContent={'center'}
          >
            {loaded
              ? collections.map((tab, index) => (
                  <Box
                    key={index}
                    bg={index == swiperIndex ? 'main.magenta' : swiperPageColor}
                    h={3}
                    w={'30%'}
                    cursor={'pointer'}
                    onClick={() => slideTo(index)}
                  />
                ))
              : [...Array(4)].map((tab, index) => (
                  <Skeleton key={index} w={'30%'} h={3} />
                ))}
          </Flex>
        </VStack>
      </Container>
    </>
  );
}
