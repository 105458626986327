/* eslint-disable react/jsx-no-target-blank */
import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Icon,
  Link,
  Stack,
  Text,
  VisuallyHidden,
  useColorModeValue,
} from "@chakra-ui/react";
import NextLink from "next/link";
import type { ReactNode } from "react";

import { FaInstagramSquare, FaTwitterSquare } from "react-icons/fa";
import { IoLocationSharp } from "react-icons/io5";
import { LogoWithTextLong } from "./logo";

export default function Footer() {
  const textSecondary = useColorModeValue("gray.600", "whiteAlpha.700");

  return (
    <Container
      position={"relative"}
      p={4}
      mt={14}
      maxW={{ base: "container.xl", "2xl": "container.2xl" }}
      borderTopWidth={1}
      borderTopColor={"alpha.purple"}
    >
      <Box>
        <Container as={Stack} maxWidth={"full"} py={10} px="0">
          <Flex
            flexDirection={{ base: "column", md: "row" }}
            justifyContent={{ md: "space-between" }}
            alignItems={"center"}
          >
            <Stack
              spacing={6}
              gridColumn={{ base: "auto", md: "auto / span 3", lg: "auto" }}
              mb={{ base: 4, md: 0 }}
            >
              <Flex
                gap={4}
                alignItems={{ base: "center", md: "flex-end" }}
                flexDirection={{ base: "column", md: "row" }}
                justifyContent={{ base: "center", md: "flex-start" }}
              >
                <Box maxW={"200px"}>
                  <LogoWithTextLong />
                </Box>
                <Text
                  color={textSecondary}
                  lineHeight={"0.8"}
                  fontWeight={"semibold"}
                >
                  © 2022 NFTOne
                </Text>
              </Flex>
              <Flex
                gap={2}
                alignItems={"flex-start"}
                flexDirection={{ base: "column", md: "row" }}
              >
                <Icon
                  display={{ base: "none", md: "block" }}
                  as={IoLocationSharp}
                  boxSize={6}
                />
                <Stack width={{ base: "full", md: "auto" }}>
                  <Text
                    fontWeight={"semibold"}
                    fontSize={"lg"}
                    textAlign={{ base: "center", md: "left" }}
                  >
                    Citywalk Sudirman Jakarta
                  </Text>
                  <Text
                    color={textSecondary}
                    textAlign={{ base: "center", md: "left" }}
                    fontSize={{ base: "sm", md: "md" }}
                  >
                    13, Jl. K.H. Mas Mansyur No.121, RT.13/RW.11, Karet Tengsin,{" "}
                    <br />
                    Kecamatan Tanah Abang, Kota Jakarta Pusat, <br />
                    Daerah Khusus Ibukota Jakarta 10220
                  </Text>
                </Stack>
              </Flex>
            </Stack>
            <Stack gap={6} alignItems={{ base: "center", md: "flex-end" }}>
              <Stack direction={"row"} spacing={{ base: 2, md: 2 }}>
                {/* <SocialButton label={"Facebook"} href={""}>
                  <FaFacebookSquare />
                </SocialButton> */}
                <SocialButton
                  label={"Twitter"}
                  href={"https://twitter.com/nftoneio"}
                >
                  <FaTwitterSquare />
                </SocialButton>
                <SocialButton
                  label={"Instagram"}
                  href={"https://www.instagram.com/nftone.io/"}
                >
                  <FaInstagramSquare />
                </SocialButton>
              </Stack>
              <Button
                variant={"gradient"}
                size={"md"}
                as={Link}
                href="mailto:contact@nftone.io"
                fontSize={"lg"}
                fontWeight={"semibold"}
                aria-label={`Contact us by email`}
                title={`Contact us by email`}
              >
                Contact Us
              </Button>
              <Flex gap={4}>
                <Link
                  as={NextLink}
                  href={`/terms?tab=terms`}
                  fontWeight={"semibold"}
                >
                  Terms & Condition
                </Link>
                <Link
                  as={NextLink}
                  href={`/terms?tab=privacy`}
                  fontWeight={"semibold"}
                >
                  Privacy Policy
                </Link>
              </Flex>
            </Stack>
          </Flex>
        </Container>
      </Box>
    </Container>
  );
}

export const SocialButton = ({
  children = null,
  label,
  href,
  color,
}: {
  children?: ReactNode;
  label: string;
  href: string;
  color?: string;
}) => {
  const defaultColor = useColorModeValue("gray.500", "gray.400");
  const socialButtonColor = color ? color : defaultColor;
  const linkHover = useColorModeValue("gray.600", "gray.100");

  return (
    <Button
      title={`NFTOne ${label}`}
      variant={"unstyled"}
      w={14}
      h={14}
      cursor={"pointer"}
      as={"a"}
      href={href}
      target={"_blank"}
      rel={"noopener noreferrer"}
      display={"inline-flex"}
      alignItems={"center"}
      justifyContent={"center"}
      transition={"all 0.3s ease"}
      color={socialButtonColor}
      fontSize={"4xl"}
      _hover={{
        color: linkHover,
      }}
      aria-label={`Contact us by ${label}`}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </Button>
  );
};
