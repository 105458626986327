import NextLink from "next/link";

import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Icon,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import {
  CardCollectionLaunchpad,
  CardCollectionLaunchpadSkeleton,
} from "components/global/card-collection";
import CardItem, { CardItemSkeleton } from "components/global/card-item";
import { FaArrowRight } from "react-icons/fa";

import { Collection, Item } from "src/API";
import { useParameterStore } from "utils/context";

export const LaunchpadMarketplacesGrid = (props: {
  loaded?: boolean;
  collections: Collection[];
}) => {
  const { loaded, collections } = props;

  return (
    <Container
      hidden={loaded && collections.length === 0}
      position={"relative"}
      maxW={{ base: "container.xl", "2xl": "container.2xl" }}
      px={4}
      pt={14}
      pb={7}
      zIndex={2}
    >
      <Flex
        flexDirection={{ base: "column", md: "row" }}
        justifyContent={"space-between"}
        gap={4}
      >
        <Box>
          <Heading
            as={"h2"}
            fontWeight={"medium"}
            fontSize={"5xl"}
            whiteSpace={"nowrap"}
            width={{ base: "full", lg: "fit-content" }}
          >
            Launchpad
          </Heading>
          <Text mt={2}>
            Discover unique & exclusive NFT&apos;s in our Launchpad Marketplace.
          </Text>
        </Box>
        <Box>
          <Button
            as={NextLink}
            href={`/launchpad`}
            variant={"gradient"}
            rightIcon={<Icon ml={2} as={FaArrowRight} />}
          >
            Explore Launchpad
          </Button>
        </Box>
      </Flex>

      <SimpleGrid
        templateColumns={{
          base: "repeat(1, 1fr)",
          sm: "repeat(2, 1fr)",
          md: "repeat(4, 1fr)",
        }}
        spacing={5}
        mt={8}
      >
        {loaded
          ? collections.map((collection, index) => (
              <CardCollectionLaunchpad key={index} collection={collection} />
            ))
          : [...Array(4)].map((i, index) => (
              <CardCollectionLaunchpadSkeleton key={index} />
            ))}
      </SimpleGrid>
    </Container>
  );
};

export const NewInMarketGrid = (props: {
  loaded?: boolean;
  items?: Item[];
}) => {
  const { loaded, items } = props;
  const { idrBalance } = useParameterStore();

  return (
    <Container
      hidden={loaded && items && items.length === 0}
      position={"relative"}
      maxW={{ base: "container.xl", "2xl": "container.2xl" }}
      px={4}
      pt={14}
      pb={7}
      zIndex={2}
    >
      <Flex
        flexDirection={{ base: "column", md: "row" }}
        justifyContent={"space-between"}
        gap={4}
      >
        <Box>
          <Heading
            as={"h2"}
            fontWeight={"medium"}
            fontSize={"5xl"}
            whiteSpace={"nowrap"}
            width={{ base: "full", lg: "fit-content" }}
          >
            New In Market
          </Heading>
          <Text mt={2}>
            Buy digital collectibles items from other enthusiasts in the
            secondary market!
          </Text>
        </Box>
        <Box>
          <Button
            as={NextLink}
            href={`/item?sale=active`}
            variant={"gradient"}
            rightIcon={<Icon ml={2} as={FaArrowRight} />}
          >
            Explore Market
          </Button>
        </Box>
      </Flex>

      <SimpleGrid
        templateColumns={{
          base: "repeat(1, 1fr)",
          sm: "repeat(2, 1fr)",
          md: "repeat(4, 1fr)",
        }}
        spacing={5}
        mt={8}
      >
        {loaded && items
          ? items.map((item, index) => (
              <CardItem
                key={index}
                items={item}
                size={"medium"}
                solToIdrPrice={idrBalance}
              />
            ))
          : [...Array(4)].map((i, index) => (
              <CardItemSkeleton key={index} size={"medium"} />
            ))}
      </SimpleGrid>
    </Container>
  );
};
