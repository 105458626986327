import { ReactElement, useEffect, useState } from "react";

import Hero from "components/features/hero";
import HeroSection2 from "components/features/hero-2";
import TopGallery from "components/features/top-gallery";
import Upcoming from "components/features/upcoming";
import IndexContainer from "components/index-container";
import MainIndex from "components/layout/main-index";

import { Box, Image as ChakraImage } from "@chakra-ui/react";

import { GraphQLQuery } from "@aws-amplify/api";
import { API, graphqlOperation } from "aws-amplify";
import {
  Collection,
  CollectionStatus,
  Item,
  ListRandomAvailableItemQuery,
  SearchCollectionsQuery,
  SearchItemsQuery,
  UserProfile,
  UserProfileByTypeQuery,
} from "src/API";
import {
  listRandomAvailableItem,
  searchCollections,
  searchItems,
  userProfileByType,
} from "src/graphql/queries";
import {
  LaunchpadMarketplacesGrid,
  NewInMarketGrid,
} from "components/features/collections-display";

interface TopGalleriesPropsObject {
  galleries: Collection[];
  loaded: boolean;
}

interface upcomingProps {
  loaded: boolean;
  creator?: UserProfile[];
}

interface HeroContentProps {
  loaded: boolean;
  item?: Item;
}

interface Hero2ContentProps {
  loaded: boolean;
  items?: Item[];
}

interface HeroCollectionSliderProps {
  loaded?: boolean;
  collections: Collection[];
}

const Home = () => {
  const [hero2, setHero2] = useState<Hero2ContentProps>({
    loaded: false,
  });
  const [topGalleryItems, setTopGalleryItems] =
    useState<TopGalleriesPropsObject>({ loaded: false, galleries: [] });
  const [upcomingItems, setUpcomingItems] = useState<upcomingProps>({
    loaded: false,
  });
  const [hero, setHero] = useState<HeroContentProps>({ loaded: false });

  const [heroCollectionSlider, setHeroCollectionSlider] =
    useState<HeroCollectionSliderProps>({ loaded: false, collections: [] });
  const [launchpadMarketplaceCollections, setLaunchpadMarketplaceCollections] =
    useState<HeroCollectionSliderProps>({ loaded: false, collections: [] });
  const [newInMarketData, setNewInMarketData] = useState<Hero2ContentProps>({
    loaded: false,
    items: [],
  });

  useEffect(() => {
    var mounted: boolean = true;

    const getHeroItem = async () => {
      try {
        const items = await API.graphql<
          GraphQLQuery<ListRandomAvailableItemQuery>
        >(graphqlOperation(listRandomAvailableItem, { noReturn: 1 }));
        if (
          items.data &&
          items.data.listRandomAvailableItem &&
          items.data.listRandomAvailableItem.items &&
          items.data.listRandomAvailableItem.items.length > 0
        ) {
          if (mounted) {
            setHero({
              loaded: true,
              item: items.data.listRandomAvailableItem.items[0]
                ? (items.data.listRandomAvailableItem.items[0] as Item)
                : undefined,
            });
          }
        } else {
          setHero({ loaded: true, item: undefined });
        }
      } catch (error) {
        console.log(error);
      }
    };

    const getListCollections = async () => {
      try {
        const collectionDisplay = await API.graphql<
          GraphQLQuery<SearchCollectionsQuery>
        >({
          query: searchCollections,
          variables: {
            limit: 6,
            sort: {
              field: "updatedAt",
              direction: "desc",
            },
            filter: {
              and: [
                { status: { ne: CollectionStatus.COMING_SOON } },
                { status: { ne: CollectionStatus.DRAFT } },
              ],
            },
          },
        });

        const launchpadMarketplaceDataAvailable = await API.graphql<
          GraphQLQuery<SearchCollectionsQuery>
        >({
          query: searchCollections,
          variables: {
            limit: 4,
            sort: {
              field: "endDate",
              direction: "asc",
            },
            filter: { status: { eq: CollectionStatus.AVAILABLE } },
          },
        });

        const launchpadMarketplaceDataComingSoon = await API.graphql<
          GraphQLQuery<SearchCollectionsQuery>
        >({
          query: searchCollections,
          variables: {
            limit: 4,
            sort: {
              field: "startDate",
              direction: "asc",
            },
            filter: { status: { eq: CollectionStatus.COMING_SOON } },
          },
        });

        if (launchpadMarketplaceDataAvailable.data?.searchCollections?.items) {
          if (
            launchpadMarketplaceDataAvailable.data?.searchCollections?.items
              .length <= 4
          ) {
            if (
              launchpadMarketplaceDataComingSoon.data?.searchCollections?.items
            ) {
              if (mounted)
                setLaunchpadMarketplaceCollections({
                  loaded: true,
                  collections: [
                    ...(launchpadMarketplaceDataAvailable.data
                      ?.searchCollections?.items as Collection[]),
                    ...(launchpadMarketplaceDataComingSoon.data
                      .searchCollections.items as Collection[]),
                  ].slice(0, 4),
                });
            } else {
              if (mounted)
                setLaunchpadMarketplaceCollections({
                  loaded: true,
                  collections: launchpadMarketplaceDataAvailable.data
                    ?.searchCollections?.items as Collection[],
                });
            }
          } else {
            if (mounted)
              setLaunchpadMarketplaceCollections({
                loaded: true,
                collections: launchpadMarketplaceDataAvailable.data
                  ?.searchCollections?.items as Collection[],
              });
          }
        }

        const getCollectionHeroSlider = await API.graphql<
          GraphQLQuery<SearchCollectionsQuery>
        >({
          query: searchCollections,
          variables: {
            limit: 5,
            sort: {
              field: "createdAt",
              direction: "desc",
            },
            filter: {
              and: [
                { bannerUrl: { exists: true } },
                { thumbnailUrl: { exists: true } },
                { description: { exists: true } },
                { status: { ne: CollectionStatus.ENDED } },
                { status: { ne: CollectionStatus.DRAFT } },
                { status: { ne: CollectionStatus.SOLD_OUT } },
              ],
            },
          },
        });

        if (getCollectionHeroSlider.data?.searchCollections?.items) {
          if (mounted) {
            setHeroCollectionSlider({
              loaded: true,
              collections: getCollectionHeroSlider.data?.searchCollections
                ?.items as Collection[],
            });
          }
        }

        if (mounted) {
          setTopGalleryItems({
            loaded: true,
            galleries: [
              ...(collectionDisplay.data?.searchCollections
                ? (collectionDisplay.data.searchCollections
                    .items as Collection[])
                : []),
            ],
          });
        }
      } catch (error) {
        console.log(error);
      }
    };

    const getListItems = async () => {
      try {
        const items = await API.graphql<
          GraphQLQuery<ListRandomAvailableItemQuery>
        >(graphqlOperation(listRandomAvailableItem, { noReturn: 3 }));
        if (
          items.data &&
          items.data.listRandomAvailableItem &&
          items.data.listRandomAvailableItem.items &&
          items.data.listRandomAvailableItem.items.length > 0
        ) {
          if (mounted) {
            setHero2({
              loaded: true,
              items: items.data.listRandomAvailableItem.items.filter(
                (f) => f !== undefined && f !== null
              ) as Item[],
            });
          }
        }

        const listItems = await API.graphql<GraphQLQuery<SearchItemsQuery>>({
          query: searchItems,
          variables: {
            limit: 4,
            sort: {
              field: "updatedAt",
              direction: "desc",
            },
            filter: {
              and: [
                { recipientAddress: { exists: true } },
                { recipientAddress: { ne: "" } },
                { recipientAddress: { ne: "-" } },
              ],
            },
          },
        });

        if (
          listItems.data?.searchItems?.items &&
          listItems.data?.searchItems?.items.length > 0
        ) {
          if (mounted)
            setNewInMarketData({
              loaded: true,
              items: listItems.data.searchItems.items as Item[],
            });
        } else {
          setNewInMarketData({ loaded: true, items: [] });
        }
      } catch (error) {
        console.log(error);
      }
    };

    const getListCreators = async () => {
      try {
        const creators = await API.graphql<
          GraphQLQuery<UserProfileByTypeQuery>
        >(
          graphqlOperation(userProfileByType, {
            type: "OFFICIAL",
          })
        );
        if (creators.data?.userProfileByType) {
          if (mounted) {
            setUpcomingItems({
              loaded: true,
              creator: creators.data.userProfileByType.items as UserProfile[],
            });
          }
        }
        console.log();
      } catch (error) {
        console.log(error);
      }
    };

    getListCollections();
    getListItems();
    getListCreators();
    getHeroItem();
    return () => {
      mounted = false;
    };
  }, []);

  return (
    <IndexContainer>
      <>
        <Box position={"absolute"} top={0} left={"55%"} opacity={0.5}>
          <ChakraImage
            alt={"light-bg"}
            src={"/images/light-1.webp"}
            width={403.5}
            height={406}
          />
        </Box>
        <Box
          position={"absolute"}
          top={"15%"}
          pt={"100px"}
          left={0}
          opacity={1}
        >
          <ChakraImage
            alt={"light-bg"}
            src={"/images/light-3.webp"}
            width={400}
            height={400}
          />
        </Box>
        <Box position={"absolute"} top={"14%"} right={0} opacity={1}>
          <ChakraImage
            alt={"light-bg"}
            src={"/images/light-3.webp"}
            width={400}
            height={400}
          />
        </Box>
        <Box position={"absolute"} top={"35%"} right={"-10%"} opacity={0.5}>
          <ChakraImage
            alt={"light-bg"}
            src={"/images/light-1.webp"}
            width={453.5}
            height={456}
          />
        </Box>
        <Box position={"absolute"} top={"50%"} left={0} opacity={1}>
          <ChakraImage
            alt={"light-bg"}
            src={"/images/light-3.webp"}
            width={400}
            height={400}
          />
        </Box>
        <Box position={"absolute"} bottom={"25%"} right={0} opacity={1}>
          <ChakraImage
            alt={"light-bg"}
            src={"/images/light-3.webp"}
            width={400}
            height={400}
          />
        </Box>
        <Box position={"absolute"} bottom={"0"} left={"5%"} opacity={0.5}>
          <ChakraImage
            alt={"light-bg"}
            src={"/images/light-1.webp"}
            width={453.5}
            height={456}
          />
        </Box>
      </>

      {/* Hero */}
      <Hero
        loaded={heroCollectionSlider.loaded}
        collections={heroCollectionSlider.collections}
      />

      <LaunchpadMarketplacesGrid
        loaded={launchpadMarketplaceCollections.loaded}
        collections={launchpadMarketplaceCollections.collections}
      />

      <NewInMarketGrid
        loaded={newInMarketData.loaded}
        items={newInMarketData.items}
      />

      {/* Top Galleries */}
      <TopGallery
        loaded={topGalleryItems.loaded}
        galleries={topGalleryItems.galleries.slice(0, 6)}
      />

      {/* Hero 2 */}
      <HeroSection2 loaded={hero2.loaded} items={hero2.items} />

      {/* Upcoming */}
      <Upcoming
        loaded={upcomingItems.loaded}
        creators={upcomingItems.creator}
        accessibleCollections={topGalleryItems.galleries}
      />
    </IndexContainer>
  );
};

Home.getLayout = (page: ReactElement) => {
  return (
    <>
      <MainIndex>{page}</MainIndex>
    </>
  );
};

export default Home;
