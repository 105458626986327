import NextLink from "next/link";
import React, { useEffect, useState } from "react";

import {
  Avatar,
  Box,
  Flex,
  Text,
  Container,
  Grid,
  GridItem,
  Heading,
  Image as ChakraImage,
  Link,
  VStack,
  Skeleton,
  useBreakpointValue,
  AspectRatio,
  useColorModeValue,
  LinkBox,
  LinkOverlay,
} from "@chakra-ui/react";

import moment from "moment";
import Moment from "react-moment";
import { motion } from "framer-motion";

import { UserProfile, Item, Collection } from "src/API";

import { Verified } from "components/global/logo";
import { API, graphqlOperation } from "aws-amplify";
import {
  collectionByCreator,
  collectionByStatus,
  itemByCollection,
  itemByCreator,
  listItems,
} from "src/graphql/queries";

export default function Upcoming(props: {
  loaded: boolean;
  creators?: UserProfile[];
  accessibleCollections?: Collection[];
}) {
  const { loaded, accessibleCollections } = props;

  const currentDateTime = moment();
  const [activeIndex, setActiveIndex] = useState<number[]>([0, 0]);
  const [creators, setCreators] = useState<UserProfile[]>(props.creators ?? []);

  const sliceIntoChunks = (arr: UserProfile[], chunkSize: number) => {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      const chunk = arr.slice(i, i + chunkSize);
      res.push(chunk);
    }
    return res;
  };

  const gridSize = useBreakpointValue({
    base: 2,
    lg: 4,
    xl: 5,
  });

  useEffect(() => {
    let mounted = true;
    if (props.creators) {
      if (accessibleCollections) {
        var newData: UserProfile[] = props.creators.filter((i) => {
          if (
            accessibleCollections.filter((f) => f.creatorId === i.id).length > 0
          ) {
            return true;
          }
          return false;
        });

        if (mounted) {
          setCreators(newData);
        }
      }
    }

    return () => {
      mounted = false;
    };
  }, [accessibleCollections, props.creators]);

  return (
    <Container
      position={"relative"}
      maxW={{ base: "container.xl", "2xl": "container.2xl" }}
      px={4}
      my={14}
      zIndex={2}
    >
      <Heading as="h2" fontWeight={"medium"} fontSize="5xl">
        Artists
      </Heading>

      <Grid
        mt={"10"}
        templateColumns={{
          base: `repeat(${gridSize !== undefined ? gridSize : 2}, 1fr)`,
          lg: `repeat(${gridSize !== undefined ? gridSize : 2}, 1fr)`,
          "2xl": `repeat(${gridSize !== undefined ? gridSize : 2}, 1fr)`,
        }}
        gap={6}
      >
        {!loaded
          ? [1, 2, 3, 4, 5].map((i, x) => (
              <GridItem
                key={x}
                rounded={"xl"}
                overflow={"hidden"}
                bg={"gradient.purpleRainbow"}
              >
                <UpcomingHeadingSkeleton />
              </GridItem>
            ))
          : gridSize !== undefined && gridSize > 2
          ? creators.length > 0 &&
            sliceIntoChunks(
              creators,
              gridSize !== undefined ? gridSize - 1 : 2
            ).map((i, x) => (
              <React.Fragment key={x}>
                {i.map((item, index) => (
                  <GridItem
                    key={index}
                    p={"1px"}
                    colSpan={activeIndex[x] == index ? 2 : 1}
                    rounded={"xl"}
                    overflow={"hidden"}
                    bg={"gradient.purpleRainbow"}
                    shadow={"xl"}
                    _hover={{ shadow: "xl" }}
                    transition={"box-shadow 0.25s ease-in-out"}
                    onMouseEnter={() =>
                      setActiveIndex((s) => {
                        s[x] = index;
                        return [...s];
                      })
                    }
                  >
                    <UpcomingHeading
                      items={item}
                      active={activeIndex[x] == index ? true : false}
                      collection={
                        accessibleCollections
                          ? accessibleCollections.filter(
                              (f) => f.creatorId === item.id
                            )[0]
                          : undefined
                      }
                    />
                  </GridItem>
                ))}
              </React.Fragment>
            ))
          : creators.map((item, index) => (
              <GridItem
                key={index}
                p={"1px"}
                colSpan={activeIndex[0] == index ? 2 : 1}
                rounded={"xl"}
                overflow={"hidden"}
                bg={"gradient.purpleRainbow"}
                shadow={"xl"}
                _hover={{ shadow: "xl" }}
                transition={"box-shadow 0.25s ease-in-out"}
                onMouseEnter={() =>
                  setActiveIndex((s) => {
                    s[0] = index;
                    return [...s];
                  })
                }
              >
                <UpcomingHeading
                  items={item}
                  active={activeIndex[0] == index ? true : false}
                  collection={
                    accessibleCollections
                      ? accessibleCollections.filter(
                          (f) => f.creatorId === item.id
                        )[0]
                      : undefined
                  }
                />
              </GridItem>
            ))}
      </Grid>
    </Container>
  );
}

const UpcomingImage = ({ image, name }: { image: string; name: string }) => {
  return (
    <AspectRatio ratio={1} m={0} h={"full"} rounded={"xl"} overflow={"hidden"}>
      <ChakraImage
        src={image}
        objectFit={"cover"}
        alt={name}
        w={"full"}
        h={"full"}
        fallback={<Skeleton w={"full"} h={"full"} />}
      />
    </AspectRatio>
  );
};

const UpcomingHeading = (props: {
  items: UserProfile;
  active: boolean;
  collection?: Collection;
}) => {
  const {
    id,
    type,
    name,
    description,
    createdAt,
    profileBannerUrl,
    profilePictureUrl,
  } = props.items;

  const { collection } = props;

  const bgUpcoming = useColorModeValue("white", "gradient.purpleDark");

  const [item, setItem] = useState<Item>();

  useEffect(() => {
    var mounted: boolean = true;

    const getCreatorItem = async () => {
      try {
        const items: any = await API.graphql(
          graphqlOperation(itemByCollection, {
            limit: 1,
            collectionId: collection?.id,
          })
        );
        if (mounted) {
          setItem(items.data.itemByCollection.items[0]);
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (collection) {
      getCreatorItem();
    }
  }, [id, collection]);

  return (
    <>
      <LinkBox
        as={Flex}
        height={{ base: 48, md: 80 }}
        w={"full"}
        rounded={"xl"}
        p={props.active ? 4 : 0}
        flexDirection={"row"}
        alignItems={"center"}
        gap={{ base: 4, md: 8 }}
        bg={bgUpcoming}
      >
        <Box
          width={props.active ? "50%" : "100%"}
          rounded={"xl"}
          height={"full"}
          zIndex={1}
          as={motion.div}
          layout
        >
          {collection ? (
            <UpcomingImage
              {...{
                image:
                  item !== undefined
                    ? item?.imageUrl
                    : "/images/default-image/default-banner-2.webp",
                name: name,
              }}
            />
          ) : (
            <UpcomingImage
              {...{
                image: "/images/default-image/coming-soon-thumbnail.webp",
                name: name,
              }}
            />
          )}
        </Box>
        <VStack
          width={"50%"}
          display={props.active ? "flex" : "none"}
          alignItems={"flex-start"}
          fontSize={"sm"}
          gap={2}
          pr={{ base: 0, md: 4 }}
        >
          <Flex direction={"row"} align={"center"} gap={2}>
            <Avatar
              display={{ base: "none", md: "inline-flex" }}
              bg={"transparent"}
              size={"sm"}
              name="avatar"
              src={
                profilePictureUrl
                  ? profilePictureUrl
                  : `${"/images/default-image/nftone-square.webp"}`
              }
            />
            <Flex
              display={"inline-flex"}
              mr={"auto"}
              fontSize={"sm"}
              align={"center"}
            >
              <Link
                as={NextLink}
                href={`/creator/${name}`}
                aria-label={`Read items and collection from ${name}`}
              >
                <Heading as={"div"} fontWeight={"semibold"} fontSize={"sm"}>
                  {name}
                  {type == "OFFICIAL" && (
                    <Flex
                      display={"inline-flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      height={"18px"}
                    >
                      <Verified />
                    </Flex>
                  )}
                </Heading>
              </Link>
            </Flex>
          </Flex>
          <Text
            fontSize={{ base: "xs", md: "sm" }}
            fontWeight={"light"}
            noOfLines={{ base: 3, md: 5 }}
          >
            {description}
          </Text>
          <Link
            as={NextLink}
            href={`/creator/${name}`}
            aria-label={`Read items and collection from ${name}`}
            color={"initial"}
          >
            <Box
              px={{ base: 2.5, md: 3.5 }}
              py={{ base: 1, md: 1.5 }}
              rounded={"2xl"}
              overflow={"hidden"}
              bgColor={"alpha.purple"}
              mt={0}
              color={useColorModeValue("gray.800", "gray.50")}
              fontSize={{ base: "xs", md: "sm" }}
              _hover={{
                color: useColorModeValue("gray.800", "gray.50"),
                filter: "brightness(90%)",
              }}
            >
              View Artist
            </Box>
          </Link>
        </VStack>
      </LinkBox>
    </>
  );
};

const UpcomingHeadingSkeleton = () => {
  return (
    <Flex
      height={{ base: 48, md: 80 }}
      w={"full"}
      rounded={"xl"}
      p={0}
      flexDirection={"row"}
      alignItems={"center"}
      gap={8}
      bg={"gradient.purpleDark"}
      as={motion.div}
      layout
    >
      <Box width={"100%"} rounded={"xl"} height={"full"} as={motion.div} layout>
        <Skeleton width={"full"} height={"full"} />
      </Box>
    </Flex>
  );
};
